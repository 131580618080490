import React, { FunctionComponent } from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { AuthorBio, BlogPostMeta, MainLayout, Seo } from "../../components";
import { IFrontmatter } from "../../types";
import { getAuthor } from "../../authors";

export const query = graphql`
  query BlogPost($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        description
        date(formatString: "MMMM D, YYYY")
        author
        image {
          publicURL
        }
        canonical
      }
      body
    }
  }
`;

type Props = {
  data: {
    mdx: {
      body: string;
      frontmatter: IFrontmatter;
    };
  };
};

const BlogPost: FunctionComponent<Props> = ({ data }) => {
  const { frontmatter, body } = data.mdx;
  const { title, description, image, canonical } = frontmatter;
  const author = getAuthor(frontmatter.author);

  return (
    <MainLayout>
      <Seo title={title} description={description} image={image?.publicURL} canonical={canonical} />

      <div className="container max-w-[50rem] mx-auto py-md">
        <Link
          to="/blog"
          className="inline-flex items-center text-lg text-blue-400 hover:text-blue-300 transition-colors ease-linear duration-150 group"
        >
          <i className="mr-1 group-hover:-translate-x-1 leading-none transition-transform ease-out duration-75 las la-arrow-left"></i>
          <span>Blog</span>
        </Link>
      </div>

      <article className="container max-w-[50rem] mx-auto prose dark:prose-invert prose-blue prose-lg">
        <BlogPostMeta author={author} frontmatter={frontmatter} />

        <h1 className="mt-5 mb-14">{title}</h1>

        <MDXRenderer>{body}</MDXRenderer>
      </article>

      <div className="container max-w-[50rem] mx-auto py-lg">
        <AuthorBio author={author} />
      </div>
    </MainLayout>
  );
};

export default BlogPost;
